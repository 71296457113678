import { Tabs as DSTabs, ITabsProps } from '@jcm/design-system';
import { memo, useState } from 'react';

import { DadosCadastraisSection } from '../cards';

import { DadosPessoais } from './dadosPessoais';
import { ErrorBoundary } from 'components';
import { Contatos } from './contatos';
import { Documentos } from './documentos';
import { Beneficiarios } from './beneficiarios';

export interface IDadosCadastraisTabsChildrenProps {
	id?: string;
	setIsNavigationDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Tabs = memo<{ id?: string }>(({ id }) => {
	const [isNavigationDisabled] = useState(false);

	const paginas: ITabsProps['items'] = [
		{
			key: 'informacoesPessoais',
			label: 'Informações Pessoais',
			children: <DadosPessoais />,
			disabled: isNavigationDisabled,
		},
		{
			key: 'contatos',
			label: 'Contatos',
			children: <Contatos />,
			disabled: isNavigationDisabled,
		},
		{
			key: 'documentos',
			label: 'Documentos',
			children: <Documentos />,
			disabled: isNavigationDisabled,
		},
		{
			key: 'beneficiarios',
			label: 'Beneficiários',
			children: <Beneficiarios />,
			disabled: isNavigationDisabled,
		},
	];

	return (
		<DadosCadastraisSection id={id ?? 'dados-cadastrais-tabs-card'}>
			<div style={{ maxWidth: '100%' }}>
				<DSTabs
					defaultActiveKey='informacoesPessoais'
					items={paginas.map((item) => {
						return { ...item, children: <ErrorBoundary>{item.children}</ErrorBoundary> };
					})}
				/>
			</div>
		</DadosCadastraisSection>
	);
});
