import { Suspense, lazy, memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { LoadingSkeleton } from 'components';

import { PaginaInicial } from './paginaInicial';

const Beneficios = lazy(() => import('./beneficios').then((module) => ({ default: module.Beneficios })));
const Saldo = lazy(() => import('./saldo').then((module) => ({ default: module.Saldo })));
const Rentabilidade = lazy(() => import('./rentabilidade').then((module) => ({ default: module.Rentabilidade })));
const Contribuicao = lazy(() => import('./contribuicao').then((module) => ({ default: module.Contribuicao })));
const Requerimentos = lazy(() => import('./requerimentos').then((module) => ({ default: module.Requerimentos })));
const DadosCadastrais = lazy(() => import('./dadosCadastrais').then((module) => ({ default: module.DadosCadastrais })));
const Simulacao = lazy(() => import('./simulacao').then((module) => ({ default: module.Simulacao })));

export const Participante = memo(() => {
	return (
		<Routes>
			<Route path='/' element={<PaginaInicial />} />
			<Route path='/saldo' element={<Suspense fallback={<LoadingSkeleton />} children={<Saldo />} />} />
			<Route path='/contribuicao/*' element={<Suspense fallback={<LoadingSkeleton />} children={<Contribuicao />} />} />
			<Route path='/rentabilidade' element={<Suspense fallback={<LoadingSkeleton />} children={<Rentabilidade />} />} />
			<Route path='/beneficios/*' element={<Suspense fallback={<LoadingSkeleton />} children={<Beneficios />} />} />
			<Route path='/simulacao/*' element={<Suspense fallback={<LoadingSkeleton />} children={<Simulacao />} />} />
			<Route path='/requerimentos' element={<Suspense fallback={<LoadingSkeleton />} children={<Requerimentos />} />} />

			<Route
				path='/dados-cadastrais'
				element={<Suspense fallback={<LoadingSkeleton />} children={<DadosCadastrais />} />}
			/>
		</Routes>
	);
});
