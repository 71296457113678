import { useMutation, useQuery } from '@tanstack/react-query';

import { IKeycloakContext, useKeycloakContext } from 'context';
import { useApi, useGlobalConfigFile, useNodeEnv } from 'hooks';

import {
	GetBeneficiariosReturnType,
	GetBeneficiosContrachequeReturnType,
	GetBeneFiciosRendasReturnType,
	GetBeneficiosSaldoDetalhadoReturnType,
	GetBeneficiosSaldosReturnType,
	GetComposicaoSaldoReturnType,
	GetContribuicoesAlteracaoCusteioBeneficioFiscalReturnType,
	GetContribuicoesAlteracaoCusteioContasReturnType,
	GetContribuicoesAlteracaoCusteioEstimativaAcumuloReturnType,
	GetContribuicoesReturnType,
	GetContribuicoesSimulacoesEstimativaAcumuloReturnType,
	GetDadosSimulacaoBeneficioArgs,
	GetDadosSimulacaoBeneficioReturnType,
	GetEmprestimoReturnType,
	GetExtratoContribuicaoArgs,
	GetExtratoContribuicaoReturnType,
	GetFichaFinanceiraAssistidoArgs,
	GetFichaFinanceiraAssistidoReturnType,
	GetHistoricoMensalContribuicoesReturnType,
	GetInformacoesTipoContaBancariaReturnType,
	GetInformacoesTipoEstadoCivilReturnType,
	GetInformacoesTipoSexoReturnType,
	GetMinhaRentabilidadeReturnType,
	GetNotificacoesReturnType,
	GetParticipacoesBeneficiosReturnType,
	GetParticipacoesComposicaoSaldoReturnType,
	GetParticipacoesDadosContaBancariaReturnType,
	GetParticipacoesDadosContatoReturnType,
	GetParticipacoesDadosPessoaisReturnType,
	GetParticipacoesDadosPlanosReturnType,
	GetParticipacoesPlanosReturnType,
	GetParticipacoesPpeReturnType,
	GetParticipacoesReturnType,
	GetParticipacoesSaldoExtratoArgs,
	GetParticipacoesSaldoExtratoReturnType,
	GetParticipacoesSaldoReturnType,
	GetParticipacoesUsPersonReturnType,
	GetTotaisContribuicoesReturnType,
	IParticipanteApi,
	ParticipanteApi,
	PostCalculoInstitutosLegaisPayloadType,
	PostCalculoInstitutosLegaisReturnType,
	PostContribuicoesAlteracaoCusteioContasPayloadType,
	PostParticipacoesDadosContaBancariaPayloadType,
	PostParticipacoesDadosContatoEmailPayloadType,
	PostParticipacoesDadosContatoEnderecoPayloadType,
	PostParticipacoesDadosContatoTelefonePayloadType,
	PostParticipacoesDadosPessoaisPayloadType,
	SendByIdFn,
} from 'api';
import { ParticipanteApi as MockParticipanteApi } from 'api/__mocks__';
import {
	IDType,
	MutationOptions,
	QueryByIdArgs,
	QueryByNullableIdArgs,
	QueryOptions,
	UseMutationByIdFn,
	UseQueryByNullableIdFn,
	UseQueryFn,
	UseQueryWithCustomArgsFn,
} from 'queries';

/**
 * Hook que retorna uma instância da ApiParticipante.
 *
 * @param kcContext Contexto do Keycloak
 * @param options
 * @returns `ParticipanteApi`, versão mockada em desenvolvimento
 */
export const useParticipanteApi = (kcContext: IKeycloakContext, options?: QueryOptions): IParticipanteApi => {
	const nodeEnv = useNodeEnv();
	const { apiConfig } = useGlobalConfigFile();

	const axiosApi = useApi({ baseURL: apiConfig.url, headers: { Authorization: `Bearer ${kcContext.accessToken}` } });

	const apiProps = { api: axiosApi, clienteId: apiConfig.clienteId };
	const mockApiProps = { delay: 1000 };

	if (options?.isProduction !== undefined) {
		return options.isProduction ? new ParticipanteApi(apiProps) : new MockParticipanteApi(mockApiProps);
	}

	return nodeEnv === 'production' ? new ParticipanteApi(apiProps) : new MockParticipanteApi(mockApiProps);
};

// TODO - Todas as rotas relacionadas ao dashboard
// componente -> beneficiários
export const useBeneficiariosQuery: UseQueryByNullableIdFn<GetBeneficiariosReturnType[]> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['beneficiarios', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getBeneficiariosById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// componente -> Contribuições
export const useTotaisContribuicoesQuery: UseQueryByNullableIdFn<GetTotaisContribuicoesReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['totaisContribuicoes', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getTotaisContribuicoesById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// componente -> Minha Rentabilidade
export const useMinhaRentabilidadeQuery: UseQueryByNullableIdFn<GetMinhaRentabilidadeReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	// TODO - Desmockar
	const participanteApi = useParticipanteApi(kcContext, { isProduction: false });

	return useQuery({
		queryKey: ['minha-rentabilidade', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getMinhaRentabilidadeById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// componente -> Empréstimo
export const useEmprestimoQuery: UseQueryByNullableIdFn<GetEmprestimoReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	// TODO - Desmockar
	const participanteApi = useParticipanteApi(kcContext, { isProduction: false });

	return useQuery({
		queryKey: ['emprestimo', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getEmprestimoById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// componente -> Composição do Saldo
export const useComposicaoSaldoQuery: UseQueryByNullableIdFn<GetComposicaoSaldoReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['composicao-saldo', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getComposicaoSaldoById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useContribuicoesQuery: UseQueryByNullableIdFn<GetContribuicoesReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contribuicoes', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getContribuicoesById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useHistoricoMensalContribuicoesQuery: UseQueryByNullableIdFn<GetHistoricoMensalContribuicoesReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['historicoMensalContribuicoes', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getHistoricoMensalContribuicoesById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useAlteracaoCusteioPlanoBasicoQuery: UseQueryByNullableIdFn<
	GetContribuicoesAlteracaoCusteioContasReturnType
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contribuicoes', 'alteracao-custeio', 'plano-basico', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getContribuicoesAlteracaoCusteioContasById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useAlteracaoCusteioEstimativaAcumuloQuery: UseQueryByNullableIdFn<
	GetContribuicoesAlteracaoCusteioEstimativaAcumuloReturnType
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contribuicoes', 'alteracao-custeio', 'estimativa-acumulo', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getContribuicoesAlteracaoCusteioEstimativaAcumuloById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useBeneficioFiscalQuery: UseQueryByNullableIdFn<
	GetContribuicoesAlteracaoCusteioBeneficioFiscalReturnType
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contribuicoes', 'alteracao-custeio', 'beneficio-fiscal', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getContribuicoesAlteracaoCusteioBeneficioFiscalById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useSimulacoesEstimativaAcumuloQuery: UseQueryByNullableIdFn<
	GetContribuicoesSimulacoesEstimativaAcumuloReturnType
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contribuicoes', 'simulacoes-estimativa-acumulo', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getContribuicoesSimulacoesEstimativaAcumuloById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// Benefícios
export const useBeneficiosSaldosQuery: UseQueryByNullableIdFn<GetBeneficiosSaldosReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['benefícios', 'saldos', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getBeneficiosSaldosById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useBeneficiosContrachequeQuery: UseQueryByNullableIdFn<GetBeneficiosContrachequeReturnType[]> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['benefícios', 'contracheque', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getBeneficiosContrachequeById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useBeneficiosRendasQuery: UseQueryByNullableIdFn<GetBeneFiciosRendasReturnType[]> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['benefícios', 'rendas', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getBeneficiosRendasById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useBeneficiosSaldoDetalhadoQuery: UseQueryByNullableIdFn<GetBeneficiosSaldoDetalhadoReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['benefícios', 'saldoDetalhado', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getBeneficiosSaldoDetalhadoById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useFichaFinanceiraAssistidoQuery: UseQueryWithCustomArgsFn<
	GetFichaFinanceiraAssistidoReturnType[],
	QueryByIdArgs<GetFichaFinanceiraAssistidoArgs>
> = (args, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	const { id, ...requestArgs } = args;

	return useQuery({
		queryKey: ['fichaFinanceira', 'assistido', args, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getFichaFinanceiraAssistidoById(id, requestArgs),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// Participações
export const useParticipacoesQuery: UseQueryByNullableIdFn<GetParticipacoesReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participações', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesSaldoQuery: UseQueryByNullableIdFn<GetParticipacoesSaldoReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participações', 'saldo', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesSaldoById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesComposicaoQuery: UseQueryByNullableIdFn<GetParticipacoesComposicaoSaldoReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participações', 'composição', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesComposicaoById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesBeneficiosQuery: UseQueryByNullableIdFn<GetParticipacoesBeneficiosReturnType[]> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participações', 'benefícios', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesBeneficiosById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesDadosPessoaisQuery: UseQueryByNullableIdFn<GetParticipacoesDadosPessoaisReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['dados-pessoais', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesDadosPessoaisById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesDadosContaBancariaQuery: UseQueryByNullableIdFn<
	GetParticipacoesDadosContaBancariaReturnType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['conta-bancaria', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesDadosContaBancariaById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesContatoQuery: UseQueryByNullableIdFn<GetParticipacoesDadosContatoReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contato', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesDadosContatoById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesDadosPlanosQuery: UseQueryByNullableIdFn<GetParticipacoesDadosPlanosReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['dados-planos', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesDadosPlanosById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesSaldoExtratoQuery: UseQueryWithCustomArgsFn<
	GetParticipacoesSaldoExtratoReturnType,
	QueryByNullableIdArgs<GetParticipacoesSaldoExtratoArgs>
> = (args, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	const { id, ...requestArgs } = args;

	return useQuery({
		queryKey: ['participações', 'saldo', 'extrato', id, requestArgs, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesSaldoExtratoById(id as NonNullable<IDType>, requestArgs),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// Header de Planos
export const useParticipacoesPlanosQuery: UseQueryByNullableIdFn<GetParticipacoesPlanosReturnType[]> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participacoes', 'planos', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesPlanosById(id),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// Extrato Contribuição (Movimentos)
export const useExtratoContribuicaoQuery: UseQueryWithCustomArgsFn<
	GetExtratoContribuicaoReturnType[],
	QueryByNullableIdArgs<GetExtratoContribuicaoArgs>
> = (args, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	const { id, ...requestArgs } = args;

	return useQuery({
		queryKey: ['extratoMovimento', id, requestArgs, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getExtratoContribuicaoById(id as NonNullable<IDType>, requestArgs),
		enabled:
			kcContext.isAuthenticated && id !== null && requestArgs.filtro === 'Custom'
				? args.dataInicio !== null && args.dataFim !== null
				: requestArgs.filtro !== null,
	});
};

export const useNotificacoesQuery: UseQueryFn<GetNotificacoesReturnType[]> = (options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['notificacoes', ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getNotificacoes(),
		enabled: kcContext.isAuthenticated,
		throwOnError: false,
	});
};

export const usePpeQuery: UseQueryByNullableIdFn<GetParticipacoesPpeReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['ppe', ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesPpeById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useUsPersonQuery: UseQueryByNullableIdFn<GetParticipacoesUsPersonReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['us-person', ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesUsPersonById(id as NonNullable<IDType>),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// Informações

export const useTipoSexoQuery: UseQueryFn<GetInformacoesTipoSexoReturnType[]> = (options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['tipo', 'sexo', ...(options?.keys ?? [])],
		queryFn: participanteApi.getInformacoesTipoSexo,
		enabled: kcContext.isAuthenticated,
	});
};

export const useTipoEstadoCivilQuery: UseQueryFn<GetInformacoesTipoEstadoCivilReturnType[]> = (options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['tipo', 'estado-civil', ...(options?.keys ?? [])],
		queryFn: participanteApi.getInformacoesTipoEstadoCivil,
		enabled: kcContext.isAuthenticated,
	});
};

export const useTipoContaBancariaQuery: UseQueryFn<GetInformacoesTipoContaBancariaReturnType[]> = (options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['tipo', 'conta-bancaria', ...(options?.keys ?? [])],
		queryFn: participanteApi.getInformacoesTipoContaBancaria,
		enabled: kcContext.isAuthenticated,
	});
};

export const useDadosSimulacaoBeneficioQuery: UseQueryWithCustomArgsFn<
	GetDadosSimulacaoBeneficioReturnType,
	QueryByNullableIdArgs<GetDadosSimulacaoBeneficioArgs>
> = (args, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	const { id, ...requestArgs } = args;

	return useQuery({
		queryKey: ['simulacao-beneficio', id, args.tipoBeneficioId, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getDadosSimulacaoBeneficio(id as NonNullable<IDType>, requestArgs),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// Mutations

/**
 * Helper que retorna uma função a ser utilizada como `mutationFn`.
 * O método checa se há um id e se há um payload, joga um erro caso não haja.
 *
 * @param id Identificador do recurso
 * @param cb Callback a ser executado depois da checagem
 * @returns Valor de `cb(id, payload)`
 * @throws `Error`, caso não haja id ou payload
 */
export const getMutationFn = <TPayload, TData = TPayload>(
	id: string | number | null,
	cb: SendByIdFn<TPayload, TData>,
) => {
	return (payload?: TPayload) => {
		if (id && payload) return cb(id, payload);

		throw new Error('id ou payload ausentes: ' + JSON.stringify({ id, payload }));
	};
};

/**
 * Hook que retorna um objeto com opções para uma mutation
 */
const useMutationOptions = <TPayload, TData = TPayload>(options?: MutationOptions<TPayload, TData>) => {
	return {
		onMutate: options?.onMutate,
		onSuccess: options?.onSuccess,
		onError: options?.onError,
		onSettled: options?.onSettled,
	} as MutationOptions<TPayload, TData>;
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados pessoais.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useParticipacoesDadosPessoaisMutation: UseMutationByIdFn<PostParticipacoesDadosPessoaisPayloadType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['dados-pessoais'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosPessoaisById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de contas bancárias.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useParticipacoesDadosContaBancariaMutation: UseMutationByIdFn<
	PostParticipacoesDadosContaBancariaPayloadType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['conta-bancaria'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosContaBancaria),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de plano.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useParticipacoesDadosPlanosMutation: UseMutationByIdFn<GetParticipacoesDadosPlanosReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['dados-planos'],
		mutationFn: getMutationFn(id, participanteApi.putParticipacoesDadosPlanosById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useParticipacoesDadosContatoMutation: UseMutationByIdFn<GetParticipacoesDadosContatoReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['contato'],
		mutationFn: getMutationFn(id, participanteApi.putParticipacoesContatoById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useUpdateParticipacoesDadosContatoTelefoneMutation: UseMutationByIdFn<
	PostParticipacoesDadosContatoTelefonePayloadType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['dados', 'contato', 'telefone'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosContatoTelefone),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useUpdateParticipacoesDadosContatoEmailMutation: UseMutationByIdFn<
	PostParticipacoesDadosContatoEmailPayloadType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['dados', 'contato', 'email'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosContatoEmail),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useUpdateParticipacoesDadosContatoEnderecoMutation: UseMutationByIdFn<
	PostParticipacoesDadosContatoEnderecoPayloadType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['dados', 'contato', 'endereço'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosContatoEndereco),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useBeneficiariosMutation: UseMutationByIdFn<GetBeneficiariosReturnType[]> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['beneficiarios'],
		mutationFn: getMutationFn(id, participanteApi.putBeneficiariosById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query do forms de PPE.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const usePpeMutation: UseMutationByIdFn<GetParticipacoesPpeReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['ppe'],
		mutationFn: getMutationFn(id, participanteApi.putParticipacoesPpeById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query do forms de US Person.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useUsPersonMutation: UseMutationByIdFn<GetParticipacoesUsPersonReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['us-person'],
		mutationFn: getMutationFn(id, participanteApi.putParticipacoesUsPersonById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query do alteração de custeio.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useAlteracaoCusteioPlanoBasicoMutation: UseMutationByIdFn<
	PostContribuicoesAlteracaoCusteioContasPayloadType
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['alteracao-custeio'],
		mutationFn: getMutationFn(id, participanteApi.postContribuicoesAlteracaoCusteioContasById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de cálculo de institutos legais.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useCalculoInstitutosLegaisMutation: UseMutationByIdFn<
	PostCalculoInstitutosLegaisPayloadType,
	PostCalculoInstitutosLegaisReturnType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(options);

	return useMutation({
		mutationKey: ['instituto-legal', id],
		mutationFn: getMutationFn(id, participanteApi.postCalculoInstitutosLegais),

		...mutOptions,
	});
};
